import React, { useState, useEffect } from "react"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import { Line } from "react-chartjs-2"
import "./tracking.scss"

// UI components
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Table from "../../components/structure/table/table"
import Badge from "../../components/ui/badge/badge"

export default function Tracking() {
	const [enquiries, setEnquiries] = useState([])
	const [trackingStats, setTrackingStats] = useState(null)
	const [dailyStats, setDailyStats] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		fetchEnquiries()
		fetchTrackingStats()
		fetchDailyStats()
	}, [])

	const fetchTrackingStats = async () => {
		try {
			const statsSnapshot = await db.collection("enquiries").orderBy("created", "desc").limit(1000).get()
			const removedEnquiriesSnapshot = await db.collection("enquiries_removed").orderBy("enquiry.created", "desc").limit(1000).get()

			// Build an array of both enquiries
			const allEnquiries = []

			// Write all the enquiries to the array
			statsSnapshot.docs.forEach((doc) => {
				allEnquiries.push(doc.data())
			})

			// Then add the "enquiry" data from the removed enquiries
			removedEnquiriesSnapshot.docs.forEach((doc) => {
				allEnquiries.push({ ...doc.data().enquiry, removed: true })
			})

			// Order by the created date, and only keep the top 1000
			allEnquiries.sort((a, b) => new Date(b.created.seconds * 1000) - new Date(a.created.seconds * 1000))
			const topEnquiries = allEnquiries.slice(0, 1000)

			let totalEnquiries = 0
			let enquiriesWithTracking = 0

			topEnquiries.forEach((data) => {
				totalEnquiries++
				if (data.tracking && Object.keys(data.tracking).length > 0) {
					enquiriesWithTracking++
				}
			})

			const percentage = ((enquiriesWithTracking / totalEnquiries) * 100).toFixed(1)
			setTrackingStats({
				total: totalEnquiries,
				withTracking: enquiriesWithTracking,
				percentage,
			})
		} catch (error) {
			console.error("Error fetching tracking stats:", error)
		}
	}

	const fetchDailyStats = async () => {
		try {
			// Get start and end of current month
			const startOfMonth = moment().startOf("month").toDate()

			// Get all enquiries from the current month
			const statsSnapshot = await db.collection("enquiries").where("created", ">=", startOfMonth).orderBy("created", "desc").get()
			const removedEnquiriesSnapshot = await db.collection("enquiries_removed").where("enquiry.created", ">=", startOfMonth).orderBy("enquiry.created", "desc").get()

			// Build an array of both enquiries
			const allEnquiries = []

			// Write all the enquiries to the array
			statsSnapshot.docs.forEach((doc) => {
				allEnquiries.push(doc.data())
			})

			// Then add the "enquiry" data from the removed enquiries
			removedEnquiriesSnapshot.docs.forEach((doc) => {
				allEnquiries.push({ ...doc.data().enquiry, removed: true })
			})

			// Order by the created date, and only keep the top 1000
			allEnquiries.sort((a, b) => new Date(b.created.seconds * 1000) - new Date(a.created.seconds * 1000))

			// Create object to store daily counts
			const dailyCounts = {}

			// Initialize all days of the month
			let currentDate = moment().startOf("month")
			const lastDate = moment().endOf("month")

			while (currentDate <= lastDate) {
				const dateKey = currentDate.format("DD/MM")
				dailyCounts[dateKey] = {
					total: 0,
					withTracking: 0,
				}
				currentDate.add(1, "days")
			}

			// Fill in the actual data
			allEnquiries.forEach((data) => {
				const date = moment(data.created.seconds * 1000).format("DD/MM")

				dailyCounts[date].total++
				if (data.tracking && Object.keys(data.tracking).length > 0) {
					dailyCounts[date].withTracking++
				}
			})

			// Convert to chart data
			const dates = Object.keys(dailyCounts).sort((a, b) => moment(a, "DD/MM") - moment(b, "DD/MM"))
			const percentages = dates.map((date) => {
				if (dailyCounts[date].total === 0) {
					return null // Return null for future dates
				}
				return ((dailyCounts[date].withTracking / dailyCounts[date].total) * 100).toFixed(1)
			})

			setDailyStats({
				labels: dates,
				datasets: [
					{
						label: "Tracking %",
						data: percentages,
						fill: false,
						borderColor: "#e41f13",
						backgroundColor: "#e41f13",
						tension: 0.1,
						pointRadius: 4,
						pointHoverRadius: 6,
					},
				],
			})
		} catch (error) {
			console.error("Error fetching daily stats:", error)
		}
	}

	const fetchEnquiries = async () => {
		try {
			// Get the last 500 enquiries and removed enquiries
			const [enquiriesSnapshot, removedEnquiriesSnapshot] = await Promise.all([
				db.collection("enquiries").orderBy("created", "desc").limit(1000).get(),
				db.collection("enquiries_removed").orderBy("enquiry.created", "desc").limit(1500).get(),
			])

			// Process each enquiry and fetch client data and booking status
			const enquiriesData = await Promise.all(
				enquiriesSnapshot.docs.map(async (doc) => {
					const enquiryData = doc.data()
					let clientData = {}
					let hasBooking = false

					// Fetch client data if client ID exists
					if (enquiryData.client) {
						try {
							const clientDoc = await db.collection("clients").doc(enquiryData.client).get()
							if (clientDoc.exists) {
								clientData = clientDoc.data()
							}
						} catch (error) {
							console.error("Error fetching client:", error)
						}
					}

					// Check if there's a booking for this enquiry
					try {
						const enquiryCreated = firebase.firestore.Timestamp.fromMillis(enquiryData.created.seconds * 1000)
						const bookingSnapshot = await db
							.collection("bookings")
							.where("client", "==", enquiryData.client) //
							.where("enquiry.created", "==", enquiryCreated)
							.limit(1)
							.get()
						hasBooking = !bookingSnapshot.empty
					} catch (error) {
						console.error("Error fetching booking:", error)
					}

					return {
						id: doc.id,
						...enquiryData,
						client: clientData,
						converted: hasBooking,
					}
				})
			)

			// Similar update for removed enquiries
			const removedEnquiriesData = await Promise.all(
				removedEnquiriesSnapshot.docs.map(async (doc) => {
					const enquiryData = doc.data().enquiry
					let clientData = {}
					let hasBooking = false

					if (enquiryData.client) {
						try {
							const clientDoc = await db.collection("clients").doc(enquiryData.client).get()
							if (clientDoc.exists) {
								clientData = clientDoc.data()
							}
						} catch (error) {
							console.error("Error fetching client:", error)
						}
					}

					// Check if there's a booking for this enquiry
					try {
						const enquiryCreated = firebase.firestore.Timestamp.fromMillis(enquiryData.created.seconds * 1000)
						const bookingSnapshot = await db
							.collection("bookings")
							.where("client", "==", enquiryData.client) //
							.where("enquiry.created", "==", enquiryCreated)
							.limit(1)
							.get()
						hasBooking = !bookingSnapshot.empty
					} catch (error) {
						console.error("Error fetching booking:", error)
					}

					return {
						id: doc.id,
						...enquiryData,
						client: clientData,
						converted: hasBooking,
						removed: true,
					}
				})
			)

			// Combine enquiries and removed enquiries
			const allEnquiries = [...enquiriesData, ...removedEnquiriesData]

			// Order the enquiries by created date
			allEnquiries.sort((a, b) => new Date(b.created.seconds * 1000) - new Date(a.created.seconds * 1000))
			console.log(allEnquiries)

			setEnquiries(allEnquiries)
			setLoading(false)
		} catch (error) {
			console.error("Error fetching enquiries:", error)
			setLoading(false)
		}
	}

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						return `${context.raw}% with tracking`
					},
				},
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				max: 100,
				title: {
					display: true,
					text: "% with tracking",
				},
			},
			x: {
				grid: {
					display: false,
				},
			},
		},
	}

	return (
		<Tile>
			<Title>
				<h1>Enquiry UTM Tracking</h1>
				{trackingStats && (
					<p className="tracking-stats">
						{trackingStats.percentage}% of the last {trackingStats.total.toLocaleString()} enquiries have tracking information ({trackingStats.withTracking.toLocaleString()} /{" "}
						{trackingStats.total.toLocaleString()})
					</p>
				)}
			</Title>

			{dailyStats && (
				<div className="tracking-chart">
					<Line
						data={dailyStats}
						options={chartOptions}
						height={150}
					/>
				</div>
			)}

			{loading ? (
				<p>Loading...</p>
			) : (
				<Table
					className="enquiries-table"
					headings={["Date", "Converted", "Site", "Client name", "Location", "Group size", "Source", "Campaign", "Medium", "Content", "Term"]}>
					{enquiries.map((enquiry) => (
						<tr key={enquiry.id}>
							<td>{moment(enquiry.created?.seconds * 1000).format("DD/MM/YYYY HH:mm")}</td>
							<td>
								{!enquiry.converted && enquiry.removed && (
									<Badge
										type="NEGATIVE"
										label="Not converted"
									/>
								)}
								{!enquiry.converted && !enquiry.removed && (
									<Badge
										type="AMBER"
										label="Open enquiry"
									/>
								)}
								{enquiry.converted && enquiry.removed && (
									<Badge
										type="POSITIVE"
										label="Converted"
									/>
								)}
							</td>
							<td>{enquiry.site || <small className="no-value">-</small>}</td>
							<td>
								{enquiry.client?.first_name || <small className="no-value">-</small>} {enquiry.client?.last_name || <small className="no-value">-</small>}
							</td>
							<td>{enquiry.location || <small className="no-value">-</small>}</td>
							<td>{enquiry.group_size || <small className="no-value">-</small>}</td>
							<td>{enquiry.tracking?.utm_source || <small className="no-value">-</small>}</td>
							<td>{enquiry.tracking?.utm_campaign || <small className="no-value">-</small>}</td>
							<td>{enquiry.tracking?.utm_medium || <small className="no-value">-</small>}</td>
							<td>{enquiry.tracking?.utm_content || <small className="no-value">-</small>}</td>
							<td>{enquiry.tracking?.utm_term || <small className="no-value">-</small>}</td>
						</tr>
					))}
				</Table>
			)}
		</Tile>
	)
}
