import React, { useState, useEffect, useContext, useRef } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { CALENDAR_MONTHS } from "../../utils/exports/months"
import { NavLink } from "react-router-dom"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./client-receipts.scss"

// UI imports
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Button from "../../components/ui/button/button"
import Table from "../../components/structure/table/table"
import exportToExcel from "./_utils/export"

// Build up a list of years for the select field
let yearsObj = {}
let nextYear = Number(moment().startOf("year").add(2, "years").format("YYYY"))
for (let i = nextYear; i >= 2018; i--) {
	yearsObj = {
		[i]: i,
		...yearsObj,
	}
}

// Returns a dashboard for viewing all the client receipts
export default function ClientReceipts() {
	const [loading, setLoading] = useState(false)
	const [payments, setPayments] = useState([])

	const [exportArr, setExportArr] = useState([])
	const [exportTitle, setExportTitle] = useState("")

	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")

	// Pull the user object from the global context
	const { user } = useContext(AuthContext)

	// When the payments are updated
	useEffect(() => {
		// Return early if there are no payments
		if (payments.length === 0 || !user?.can_export) return

		// Build a file name from the parameters
		setExportTitle(`ClientReceipts_${month ? month : "All"}${year}`)

		// Otherwise, loop through the payments
		const exportData = payments.map((payment) => {
			// Pull all the data from the payment object
			const { paid_date_string, amount, payee, reference, booking, agent } = payment

			// Return the booking details
			return {
				paid_date: paid_date_string,
				amount_paid: amount,
				payee: payee,
				reference: reference,
				booking_reference: booking?.reference,
				check_in: booking?.check_in_string,
				saved_by: agent?.first_name && `${agent?.first_name} ${agent?.last_name}`,
			}
		})

		// Set the export data into the state
		setExportArr(exportData)
	}, [payments])

	const queryPaymentsCollection = async () => {
		// Reset the state
		setLoading(true)

		// Get the milliseconds timestamps from the chosen filters
		let fetchStart = 0
		let fetchEnd = 0
		if (month && year) {
			fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
			fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		} else if (!month && year) {
			fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf()
			fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf()
		}
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

		try {
			// Fetch all payments in date range
			const querySnapshot = await db.collectionGroup("payments").where("paid_date", ">=", startTimestamp).where("paid_date", "<=", endTimestamp).get()

			// Setup some arrays for the payments data and query promises
			const paymentsArray = []
			const promises = []

			// Map over each of the payment docs that were retruned
			querySnapshot.forEach((doc) => {
				const paymentID = doc.id
				const paymentData = doc.data()

				// Format the paid date into a string
				const paidDate = moment(paymentData.paid_date?.seconds, "X").format("MMM D[,] YYYY")

				// Format the amount paid
				const amountPaid = new Intl.NumberFormat("en-GB", {
					style: "currency",
					currency: "GBP",
				}).format(paymentData.amount)

				// Get the booking reference
				const bookingRef = doc.ref.parent.parent

				// Build a promise chain to get booking data and agent data
				const fetchBookingAndAgent = async () => {
					let bookingData = {}
					let agentData = {}

					// Fetch the booking data
					if (bookingRef) {
						const bookingDoc = await bookingRef.get()
						if (bookingDoc.exists) {
							// Format the date strings
							const checkInDate = moment(bookingDoc.data().check_in?.seconds, "X").format("MMM D[,] YYYY")
							const bookedDate = moment(bookingDoc.data().booked?.seconds, "X").format("MMM D[,] YYYY")

							bookingData = {
								id: bookingDoc.id,
								check_in_string: checkInDate,
								booked_string: bookedDate,
								...bookingDoc.data(),
							}
						}
					}

					// Fetch the agent data
					if (paymentData.saved_by) {
						const agentDoc = await db.collection("users").doc(paymentData.saved_by).get()
						if (agentDoc.exists) {
							agentData = {
								id: agentDoc.id,
								...agentDoc.data(),
							}
						}
					}

					// Return a single object combining everything we care about
					return {
						paymentID,
						...paymentData,
						amount_paid: amountPaid,
						paid_date_string: paidDate,
						booking: bookingData,
						agent: agentData,
					}
				}

				// Push the fetch function into our promises array
				promises.push(fetchBookingAndAgent())
			})

			// Wait until all booking & agent fetches are done
			const results = await Promise.all(promises)

			// Push them into our paymentsArray
			paymentsArray.push(...results)

			// Set into state or do something similar:
			setPayments(paymentsArray)
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Client Receipts</h1>
			</Title>

			<div className="page-filters extra-stats">
				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={yearsObj}
				/>

				<div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
					<Button
						filters={true}
						disabled={(!month || !year) && !year}
						label="Fetch stats"
						loadingText="Fetching stats..."
						loading={loading}
						onClick={() => queryPaymentsCollection()}
					/>

					{user?.can_export && (
						<div>
							<Button
								filters={true}
								disabled={exportArr.length === 0}
								label="Export to XLSX"
								onClick={() => exportToExcel(exportArr, exportTitle)}
							/>
						</div>
					)}
				</div>
			</div>

			<Table
				className="travellers-table"
				headings={["Paid date", "Amount", "Payee", "Payment Reference", "Booking Reference", "Checking in", "Saved by", ""]}
				noResults={Object.entries(payments).length === 0}
				noResultsMessage={"No results matching those filters"}>
				{payments.map((payment) => (
					<tr key={payment.id}>
						<td>{payment.paid_date_string}</td>
						<td>{payment.amount_paid}</td>
						<td>{payment.payee}</td>
						<td>{payment.reference}</td>
						<td>{payment.booking?.reference}</td>
						<td>{payment.booking?.check_in_string}</td>
						<td>
							{payment.agent?.first_name && `${payment.agent?.first_name} ${payment.agent?.last_name}`}
							{!payment.agent?.first_name && <small className="no-value">No name set</small>}
						</td>
						<td className="is-button">
							<NavLink to={`booking/${payment.booking?.id}`}>View booking</NavLink>
							<a
								target="_blank"
								rel="noreferrer"
								href={`booking/${payment.booking?.id}`}>
								View in new tab
							</a>
						</td>
					</tr>
				))}
			</Table>
		</Tile>
	)
}
