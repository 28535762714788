import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { db } from "../../utils/firebase"
import "./cancellations.scss"

// UI imports
import { LoadingIcon } from "../../utils/svgs"
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Table from "../../components/structure/table/table"
import ViewCancellation from "../../components/cancellation/view/view"
import Cancellation from "../../components/cancellation/cancellation"

// Functional component to return the booking cancellations
export default function Cancellations() {
	const [loading, setLoading] = useState(true)
	const [bookings, setBookings] = useState([])
	const [viewingCancellation, setViewingCancellation] = useState("")

	// Pull some data from the auth context
	const { user } = useContext(AuthContext)

	// On component load
	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true)

				// Fetch all cancellations
				const cancellationsSnapshot = await db.collection("cancellations").orderBy("cancelled_at", "desc").get()

				// Map through cancellations and fetch related documents
				const cancellationsWithDetails = await Promise.all(
					cancellationsSnapshot.docs.map(async (doc) => {
						const cancellation = { id: doc.id, ...doc.data() }

						// Fetch agent details if exists
						let agentData = {}
						if (cancellation.booking_data.agent) {
							const agentDoc = await db.collection("users").doc(cancellation.booking_data.agent).get()
							if (agentDoc.exists) {
								agentData = { id: agentDoc.id, ...agentDoc.data() }
							}
						}

						// Fetch client details if exists
						let clientData = {}
						if (cancellation.booking_data.client) {
							const clientDoc = await db.collection("clients").doc(cancellation.booking_data.client).get()
							if (clientDoc.exists) {
								clientData = { id: clientDoc.id, ...clientDoc.data() }
							}
						}

						// Return combined data
						return {
							...cancellation,
							agentData,
							agent_name: agentData.first_name + " " + agentData.last_name,
							clientData,
							client_name: clientData.first_name + " " + clientData.last_name,
						}
					})
				)

				setBookings(cancellationsWithDetails)
				setLoading(false)
			} catch (error) {
				console.error("Error fetching cancellations:", error)
				setLoading(false)
			}
		}

		fetchData()
	}, [])

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Booking cancellations</h1>
			</Title>

			<Table
				className="bookings-table"
				headings={["Client name", "Agent assigned", "Booking margin", "Paid by client", "Supplier invoices", "Cancelled on", "Original travel date", "Confirmed with resort", ""]}
				noResults={bookings.length === 0}
				noResultsMessage="No cancellations to manage">
				{bookings.map((cancellation) => (
					<Cancellation
						id={cancellation.id}
						key={cancellation.id}
						details={cancellation}
						maskDetails={user?.mask_details}
						showCancellationWindow={(cancellationID) => setViewingCancellation(cancellationID)}
					/>
				))}

				{loading && (
					<div className="table-loading-splash">
						<div className="loading-wrapper">
							<p>Loading...</p>
							<div className="svg-loading-wrap">
								<LoadingIcon />
							</div>
						</div>
					</div>
				)}
			</Table>

			{viewingCancellation && (
				<ViewCancellation
					cancellationID={viewingCancellation}
					close={() => setViewingCancellation("")}
				/>
			)}
		</Tile>
	)
}
