import { useEffect, useState } from "react"
import { db } from "../../../utils/firebase"

// ChartJS imports
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from "chart.js"

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip)

export default function BookingsLineChart({ tmStart, tmEnd, lyStart, lyEnd }) {
	const [tmBookings, setTmBookings] = useState([])
	const [lyBookings, setLyBookings] = useState([])

	// On load
	useEffect(() => {
		fetchThisMonth()
		fetchLastYear()
	}, [])

	// Fetch this months stats
	const fetchThisMonth = async () => {
		const bookings = await db
			.collection("bookings")
			.where("created", ">", tmStart)
			.where("created", "<", tmEnd)
			.get()
			.then((bookingDocs) => {
				let bookings = []
				bookingDocs.forEach((bookingDoc) => {
					bookings.push({ id: bookingDoc.id, ...bookingDoc.data() })
				})
				return bookings
			})
		setTmBookings(bookings)
	}

	// Fetch last years stats
	const fetchLastYear = async () => {
		const bookings = await db
			.collection("bookings")
			.where("created", ">", lyStart)
			.where("created", "<", lyEnd)
			.get()
			.then((bookingDocs) => {
				let bookings = []
				bookingDocs.forEach((bookingDoc) => {
					bookings.push({ id: bookingDoc.id, ...bookingDoc.data() })
				})
				return bookings
			})
		setLyBookings(bookings)
	}

	// Process data to get the number of Bookings for each day
	const processBookings = (data) => {
		const counts = {}

		data.forEach((booking) => {
			const date = new Date(booking.created.seconds * 1000)
			const normalizedDate = date.toLocaleDateString("en-GB", {
				day: "2-digit",
				month: "2-digit",
			})
			counts[normalizedDate] = (counts[normalizedDate] || 0) + 1
		})

		// Sort dates and calculate cumulative total
		const sortedDates = Object.keys(counts).sort((a, b) => {
			const [dayA, monthA] = a.split("/").map(Number)
			const [dayB, monthB] = b.split("/").map(Number)
			return monthA === monthB ? dayA - dayB : monthA - monthB
		})

		const cumulativeCounts = {}
		let runningTotal = 0
		sortedDates.forEach((date) => {
			runningTotal += counts[date]
			cumulativeCounts[date] = runningTotal
		})

		return cumulativeCounts
	}

	const currentYearCounts = processBookings(tmBookings)
	const lastYearCounts = processBookings(lyBookings)

	// Get all unique dates across both datasets
	const allDates = [...new Set([...Object.keys(currentYearCounts), ...Object.keys(lastYearCounts)])].sort((a, b) => new Date(a) - new Date(b))

	// Get today as a string
	const today = new Date().toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
	})

	// Generate datasets for Chart.js
	const currentYearDataset = allDates.map((date, index, dates) => {
		if (date > today) {
			return null
		}
		// If it's today and the value is 0, use yesterday's value
		if (date === today && (!currentYearCounts[date] || currentYearCounts[date] === 0)) {
			// Get yesterday's date from the dates array
			const yesterdayDate = dates[index - 1]
			return currentYearCounts[yesterdayDate] || 0
		}
		return currentYearCounts[date] || 0
	})
	const lastYearDataset = allDates.map((date) => lastYearCounts[date] || 0)

	const chartData = {
		labels: allDates,
		datasets: [
			{
				label: "This Year",
				data: currentYearDataset,
				borderColor: "#e41f13",
				backgroundColor: "#e41f13",
				fill: false,
			},
			{
				label: "Last Year",
				data: lastYearDataset,
				borderColor: "grey",
				backgroundColor: "grey",
				pointRadius: 2,
				borderDash: [5, 5],
				borderWidth: 2,
				fill: false,
			},
		],
	}

	const chartOptions = {
		responsive: true,
		animations: {
			y: {
				easing: "easeOutQuart",
				duration: 2000,
				from: (ctx) => {
					if (ctx.type === "data") {
						if (ctx.mode === "default" && !ctx.dropped) {
							ctx.dropped = true
							return ctx.chart.scales.y.bottom
						}
					}
					return ctx.chart.scales.y.bottom
				},
			},
			x: {
				duration: 0,
			},
		},
		plugins: {
			legend: {
				position: "top",
			},
			tooltip: {
				enabled: true,
				mode: "index",
				intersect: false,
				callbacks: {
					label: function (context) {
						const datasetIndex = context.datasetIndex
						const currentValue = context.raw
						const comparisonValue = datasetIndex === 0 ? context.chart.data.datasets[1].data[context.dataIndex] || 0 : context.chart.data.datasets[0].data[context.dataIndex] || 0

						const diff = currentValue - comparisonValue
						const percentageDiff = comparisonValue ? ((diff / comparisonValue) * 100).toFixed(2) : "N/A"

						// Add custom message based on dataset
						if (datasetIndex === 0) {
							return `This Year: ${currentValue} (${diff >= 0 ? "+" : ""}${diff}, ${percentageDiff !== "N/A" ? percentageDiff + "%" : "N/A"})`
						} else {
							return `Last Year: ${currentValue}`
						}
					},
					labelColor: () => {
						return {
							borderColor: "transparent",
							backgroundColor: "transparent",
						}
					},
				},
			},
		},
		interaction: {
			mode: "index",
			intersect: false,
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Dates (DD/MM)",
				},
			},
			y: {
				title: {
					display: true,
					text: "Cumulative Bookings",
				},
			},
		},
	}

	// Custom plugin for drawing text above dots
	const customLabelsPlugin = {
		id: "customLabels",
		afterRender(chart) {
			const { ctx, chartArea, scales } = chart
			const currentYearMeta = chart.getDatasetMeta(0)
			const lastYearDataset = chart.data.datasets[1].data

			ctx.save()

			// Draw custom labels above dots for current year
			currentYearMeta.data.forEach((point, index) => {
				const currentValue = chart.data.datasets[0].data[index]
				const lastYearValue = lastYearDataset[index] || 0

				// Skip drawing if the value is 0
				if (!currentValue || currentValue === 0) return

				const diff = currentValue - lastYearValue
				const percentageDiff = lastYearValue === 0 ? "N/A" : ((diff / lastYearValue) * 100).toFixed(2)

				// Draw the value and difference above the dot
				const text = `${currentValue} (${percentageDiff}%)`
				ctx.font = "10px Poppins"
				ctx.fillStyle = "black"
				ctx.textAlign = "center"
				ctx.fillText(text, point.x, point.y - 20)
			})

			// Define all events with their dates and labels
			const events = []

			// Draw vertical lines and labels for each event
			events.forEach(({ date, labels, yOffset }) => {
				const xValue = scales.x.getPixelForValue(date)

				if (xValue) {
					// Draw vertical line
					ctx.strokeStyle = "rgba(255, 99, 132, 0.5)"
					ctx.lineWidth = 1
					ctx.beginPath()
					ctx.moveTo(xValue, chartArea.top)
					ctx.lineTo(xValue, chartArea.bottom)
					ctx.stroke()

					// Draw labels
					ctx.font = "10px Poppins"
					ctx.fillStyle = "rgba(255, 99, 132, 0.75)"
					ctx.textAlign = "center"

					labels.forEach((label, index) => {
						ctx.fillText(label, xValue, chartArea.top + yOffset + index * 15)
					})

					ctx.restore()
				}
			})
		},
	}

	return (
		<div>
			<h2 style={{ fontSize: "18px", marginBottom: "4px" }}>Booking Trends</h2>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>This month: {tmBookings.length}</p>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>Last year: {lyBookings.length}</p>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>Difference: {tmBookings.length - lyBookings.length}</p>
			<br />
			<div style={{ height: "675px", width: "100%" }}>
				<Line
					data={chartData}
					options={chartOptions}
					plugins={[customLabelsPlugin]}
				/>
			</div>
		</div>
	)
}
