import React, { useState, useEffect } from "react"
import { db } from "../../utils/firebase"
import moment from "moment"
import "./tracking-analytics.scss"

// UI components
import Tile from "../../components/structure/tile/tile"
import Table from "../../components/structure/table/table"
import Title from "../../components/structure/title/title"

export default function TrackingAnalytics() {
	const [loading, setLoading] = useState(true)
	const [trackingData, setTrackingData] = useState([])

	useEffect(() => {
		fetchTrackingData()
	}, [])

	const fetchTrackingData = async () => {
		try {
			const [enquiries, deletedEnquiries] = await Promise.all([
				await db
					.collection("enquiries")
					.where("tracking", "!=", null)
					.get()
					.then((docs) => {
						return docs.docs.map((doc) => doc.data())
					}),
				await db
					.collection("enquiries_removed")
					.where("enquiry.tracking", "!=", null)
					.get()
					.then((docs) => {
						return docs.docs.map((doc) => doc.data().enquiry)
					}),
			])

			const allEnquiries = [...enquiries, ...deletedEnquiries]
			console.log(allEnquiries)

			let trackingStats = {}

			allEnquiries.forEach((data) => {
				if (data && data.tracking) {
					const source = data.tracking.utm_source || "Unknown"
					const campaign = data.tracking.utm_campaign || "Unknown"
					const medium = data.tracking.utm_medium || "Unknown"
					const term = data.tracking.utm_term || "None"
					const content = data.tracking.utm_content || "None"
					const timestamp = data.created?.seconds

					// Create nested structure if it doesn't exist
					if (!trackingStats[source]) {
						trackingStats[source] = {}
					}
					if (!trackingStats[source][campaign]) {
						trackingStats[source][campaign] = {
							count: 0,
							mediums: {},
							terms: {},
							contents: {},
							firstSeen: timestamp,
						}
					}

					// Update firstSeen if this entry is more recent
					trackingStats[source][campaign].firstSeen = timestamp < trackingStats[source][campaign].firstSeen ? timestamp : trackingStats[source][campaign].firstSeen

					// Increment counts
					trackingStats[source][campaign].count++

					// Track medium
					if (!trackingStats[source][campaign].mediums[medium]) {
						trackingStats[source][campaign].mediums[medium] = 0
					}
					trackingStats[source][campaign].mediums[medium]++

					// Track term
					if (!trackingStats[source][campaign].terms[term]) {
						trackingStats[source][campaign].terms[term] = 0
					}
					trackingStats[source][campaign].terms[term]++

					// Track content
					if (!trackingStats[source][campaign].contents[content]) {
						trackingStats[source][campaign].contents[content] = 0
					}
					trackingStats[source][campaign].contents[content]++
				}
			})

			// Convert the nested structure into a sorted array before setting state
			const sortedTrackingData = Object.entries(trackingStats)
				.flatMap(([source, campaigns]) =>
					Object.entries(campaigns).map(([campaign, data]) => ({
						source,
						campaign,
						data,
					}))
				)
				.sort((a, b) => new Date(b.data.firstSeen) - new Date(a.data.firstSeen))

			setTrackingData(sortedTrackingData)
			setLoading(false)
		} catch (error) {
			console.error("Error fetching tracking data:", error)
			setLoading(false)
		}
	}

	return (
		<Tile fullPage={true}>
			<Title>
				<h1>UTM Parameter Analytics</h1>
				<p>All tracking data from enquiries</p>
			</Title>

			{loading ? (
				<p>Loading...</p>
			) : (
				<Table
					className="enquiries-table"
					headings={["Source", "Campaign", "First Seen", "Enquiries", "Mediums", "Terms", "Content"]}>
					{trackingData.map(({ source, campaign, data }) => (
						<tr key={`${source}-${campaign}`}>
							<td>{source}</td>
							<td>{campaign}</td>
							<td>{moment(data.firstSeen, "X").format("MMM DD, YYYY")}</td>
							<td>{data.count}</td>
							<td>
								<ul className="medium-list">
									{Object.entries(data.mediums)
										.sort((a, b) => b[1] - a[1])
										.map(([medium, count]) => (
											<li key={medium}>
												{medium} <small>({count})</small>
											</li>
										))}
								</ul>
							</td>
							<td>
								<ul className="medium-list">
									{Object.entries(data.terms)
										.sort((a, b) => b[1] - a[1])
										.map(([term, count]) => (
											<li key={term}>
												{term} <small>({count})</small>
											</li>
										))}
								</ul>
							</td>
							<td>
								<ul className="medium-list">
									{Object.entries(data.contents)
										.sort((a, b) => b[1] - a[1])
										.map(([content, count]) => (
											<li key={content}>
												{content} <small>({count})</small>
											</li>
										))}
								</ul>
							</td>
						</tr>
					))}
				</Table>
			)}
		</Tile>
	)
}
