import React, { useEffect, useState } from "react"
import { db } from "../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"
import "./booking.scss"

/**
 * Functional component to return the enquiry table row
 */
function Booking({ id, details, maskDetails }) {
	const [agent, setAgent] = useState({})

	/**
	 * Generate a string to represent the date_from date from the timestamps
	 */
	const dateFrom = moment(details.date_from?._seconds, "X").format("MMM D[,] YYYY")
	const dateBooked = moment(details.created?._seconds, "X").format("MMM D[,] YYYY HH:mm")

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Get the agent record from the database
		 */
		db.doc(`users/${details.agent}`)
			.get()
			.then((agentDoc) => {
				/**
				 * Set the agent data into the local state
				 */
				setAgent(agentDoc.data())
			})
	}, [])

	return (
		<tr>
			<td>{details.enquiry?.site}</td>
			<td>
				{agent?.first_name && `${agent.first_name} ${agent.last_name}`}
				{!agent?.first_name && <small className="no-value">No agent assigned</small>}
			</td>
			<td>{dateBooked}</td>
			<td>
				{!maskDetails && (
					<NavLink to={`client/${details.client}`}>
						{details.first_name && `${details.first_name} ${details.last_name}`}
						{!details.first_name && <small className="no-value">No name set</small>}
					</NavLink>
				)}

				{maskDetails && (
					<>
						{details.first_name && `${details.first_name} ${details.last_name}`}
						{!details.first_name && <small className="no-value">No name set</small>}
					</>
				)}
			</td>
			<td>{details.phone}</td>
			<td>{details.booked_location ? details.booked_location : details.enquiry?.location}</td>
			<td>{dateFrom}</td>
			<td>£{details.booking_type === "DIRECT" ? details?.total_cost : details.enquiry?.quoted}</td>
			<td className="is-button">
				<NavLink to={`booking/${id}`}>View booking</NavLink>
			</td>
		</tr>
	)
}

export default Booking
