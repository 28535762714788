import React, { useEffect, useState, useContext } from "react"
import { db, auth, arrayRemove, arrayUnion } from "../../../utils/firebase"
import { PenIcon, SaveIcon } from "../../../utils/svgs"
import { ModalContext } from "../../../utils/providers/modal"
import firebase from "firebase"
import moment from "moment"
import randomstring from "randomstring"

/**
 * UI components
 */
import Window from "../../structure/window/window"
import WindowCard from "../../ui/window-card/window-card"
import Input from "../../ui/inputs/input"
import Textarea from "../../ui/inputs/textarea"
import Button from "../../ui/button/button"
import Datepicker from "../../ui/datepicker/datepicker"
import { Redirect } from "react-router"
import Select from "../../ui/select/select"

/**
 * Functional component to return a window full screen with the enquiry details
 */
function ViewEnquiry(props) {
	const [editing, setEditing] = useState(false)
	const [saving, setSaving] = useState(false)
	const [confirming, setConfirming] = useState(false)
	const [removing, setRemoving] = useState(false)
	const [status, setStatus] = useState("")
	const [clientID, setClientID] = useState("")
	const [agent, setAgent] = useState("")
	const [staticAgent, setStaticAgent] = useState("")
	const [site, setSite] = useState("")
	const [agents, setAgents] = useState("")
	const [client, setClient] = useState({})
	const [created, setCreated] = useState({})
	const [dateFrom, setDateFrom] = useState({})
	const [dateFromMillis, setDateFromMillis] = useState(0)
	const [location, setLocation] = useState("")
	const [nights, setNights] = useState("")
	const [rounds, setRounds] = useState("")
	const [groupSize, setGroupSize] = useState("")
	const [quoted, setQuoted] = useState("")
	const [notes, setNotes] = useState("")
	const [followUpDate, setFollowUpDate] = useState(0)
	const [redirect, setRedirect] = useState("")
	const [parameters, setParameters] = useState({})

	/**
	 * Deconstruct the props
	 */
	const { enquiryID } = props

	/**
	 * Pull the show modal function from the modal context
	 */
	const { showModal } = useContext(ModalContext)

	/**
	 * Build a string to represent the date from timestamp
	 */
	const dateFromString = moment(dateFrom?.seconds, "X").format("MMM D[,] YYYY")

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Get the enquiry document from the database
		 */
		console.log(enquiryID)
		db.doc(`enquiries/${enquiryID}`)
			.get()
			.then((enquiryDoc) => {
				/**
				 * Deconstruct the enquiry data
				 */
				const { status, agent, client, created, date_from, location, nights, rounds, group_size, quoted, notes, follow_up, site, tracking } = enquiryDoc.data()
				/**
				 * Set the enquiry data into the state
				 */
				setStatus(status)
				setAgent(agent)
				setStaticAgent(agent)
				setSite(site)
				setCreated(created)
				setDateFrom(date_from)
				setLocation(location)
				setNights(nights)
				setRounds(rounds)
				setGroupSize(group_size)
				setQuoted(quoted)
				setNotes(notes)
				setClientID(client)
				setParameters(tracking || {})
				/**
				 * Generate a milliseconds timestamp for the follow up date
				 */
				const followUpDateMillis = moment(follow_up?.seconds, "X").valueOf()
				setFollowUpDate(followUpDateMillis)
				/**
				 * Generate a milliseconds timestamp for the date_from date
				 */
				const dateFromAsMillis = moment(date_from?.seconds, "X").valueOf()
				setDateFromMillis(dateFromAsMillis)
			})
	}, [enquiryID])

	/**
	 * On load for the context
	 */
	useEffect(() => {
		/**
		 * Pull a list of agents from the use list
		 */
		db.collection("users")
			.orderBy("first_name")
			.where("show_for.assignable", "==", true)
			.get()
			.then((agentDocs) => {
				let agentsArr = []
				/**
				 * Loop through the agent documents found
				 */
				agentDocs.forEach((agentDoc) => {
					/**
					 * Deconstruct the agent details
					 */
					const { first_name, last_name } = agentDoc.data()
					/**
					 * Add them into the array
					 */
					agentsArr[agentDoc.id] = `${first_name} ${last_name}`
				})
				/**
				 * Push the user into the state
				 */
				setAgents(agentsArr)
			})
	}, [])

	/**
	 * When the clientID is updated
	 */
	useEffect(() => {
		/**
		 * Get the client document from the database
		 */
		clientID &&
			db
				.doc(`clients/${clientID}`)
				.get()
				.then((clientDoc) => {
					/**
					 * Set the client details into the state
					 */
					setClient(clientDoc.data())
				})
	}, [clientID])

	/**
	 * Save the enquiry details into the database
	 */
	const saveEnquiryDetails = async () => {
		/**
		 * Show a spinner on the save button
		 */
		setSaving(true)
		/**
		 * Generate a firebase timestamp from the follow up date
		 */
		const followUpTimestamp = firebase.firestore.Timestamp.fromMillis(followUpDate)
		/**
		 * Save the details into the database for the enquiry
		 */
		await db.doc(`enquiries/${enquiryID}`).set(
			{
				agent,
				location,
				nights,
				rounds,
				group_size: groupSize,
				quoted,
				notes: notes || "",
				status,
				follow_up: followUpDate ? followUpTimestamp : null,
			},
			{ merge: true }
		)

		/**
		 * If the agent has changed, add a log to the enquiry
		 */
		if (agent !== staticAgent) {
			await db.collection(`LOGS_enquiries`).add({
				type: "REASSIGNED",
				created: firebase.firestore.FieldValue.serverTimestamp(),
				enquiry: enquiryID,
				fromUser: staticAgent,
				toUser: agent,
				byUser: auth.currentUser.uid,
			})
		}

		/**
		 * Reset the state
		 */
		setEditing(false)
		setSaving(false)
	}

	/**
	 * Check before removing the enquiry
	 */
	const checkToRemoveEnquiry = () => {
		/**
		 * Display the modal to check before an enquiry removal
		 */
		showModal({
			type: "ALERT",
			title: "Are you sure?",
			body: "Once removed, you won't be able to recover the data for this enquiry",
			cancel: {
				label: "Cancel",
				action: () => {
					return null
				},
			},
			next: {
				label: "Yes, i'm sure",
				action: () => removeEnquiry(),
			},
		})
	}

	/**
	 * Remove the enquiry from the database
	 */
	const removeEnquiry = async () => {
		/**
		 * Update the state
		 */
		setRemoving(true)
		/**
		 * Remove the enquiry ID from the clients enquiries array
		 */
		await db.doc(`clients/${clientID}`).set(
			{
				enquiries: arrayRemove(enquiryID),
			},
			{ merge: true }
		)
		/**
		 * Then remove the enquiry document
		 */
		await db.doc(`enquiries/${enquiryID}`).delete()
		/**
		 * Reset the state
		 */
		setRemoving(false)
		props.close()
	}

	/**
	 * Create a 7 digit booking reference, and check to make sure it doesn't already exist
	 */
	const createABookingReference = async () => {
		/**
		 * Create a variable to store the booking reference
		 */
		let reference = ""
		/**
		 * While this variable is empty
		 */
		while (!reference) {
			/**
			 * Generate a booking reference
			 */
			const temporaryReference = randomstring.generate({
				length: 7,
				charset: "123456789",
			})
			/**
			 * Search the database for a booking with the same reference
			 */
			await db
				.collection("bookings")
				.where("reference", "==", reference)
				.get()
				.then((bookingDoc) => {
					/**
					 * If there is a booking document with the same reference
					 */
					if (bookingDoc.exists) {
						/**
						 * Just return false
						 */
						return false
					} else {
						/**
						 * Otherwise set the temporary reference to the main one to cancel the while loop
						 */
						reference = temporaryReference
					}
				})
		}
		/**
		 * Return the booking reference
		 */
		return reference
	}

	/**
	 * Confirm the enquiry as a booking
	 */
	const confirmEnquiryAsBooking = async () => {
		/**
		 * Update the state with a spinner on the confirming button
		 */
		setConfirming(true)
		/**
		 * Create a booking reference for the booking document
		 */
		const bookingReference = await createABookingReference()
		/**
		 * Make a new booking record with the enquiry details
		 */
		const bookingID = await db
			.collection("bookings")
			.add({
				agent,
				client: clientID,
				reference: Number(bookingReference),
				booked: firebase.firestore.FieldValue.serverTimestamp(),
				created: firebase.firestore.FieldValue.serverTimestamp(),
				date_from: firebase.firestore.Timestamp.fromMillis(dateFromMillis),
				enquiry: {
					date_from: firebase.firestore.Timestamp.fromMillis(dateFromMillis),
					location: location || "",
					nights: nights || 0,
					rounds: rounds || 0,
					group_size: groupSize || 0,
					quoted: quoted || 0,
					notes: notes || "",
					created,
					status,
					site,
				},
				paid_by_client: 0,
				removed: false,
			})
			.then((bookingDoc) => {
				return bookingDoc.id
			})
		/**
		 * Add a log to the booking document
		 */
		await db.collection(`bookings/${bookingID}/logs`).add({
			type: "BOOKING_CREATED",
			message: "Booking was created from an existing enquiry",
			user: auth.currentUser.uid,
			created: firebase.firestore.FieldValue.serverTimestamp(),
		})
		/**
		 * Add the bookingID to the clients booking array and remove the enquiry
		 */
		await db.doc(`clients/${clientID}`).set(
			{
				enquiries: arrayRemove(enquiryID),
				bookings: arrayUnion(bookingID),
			},
			{ merge: true }
		)
		/**
		 * Add a log to the client document
		 */
		await db.collection(`clients/${clientID}/logs`).add({
			type: "BOOKING_CREATED",
			badge: "SUCCESS",
			message: `Enquiry was converted to a booking for ${location}`,
			user: auth.currentUser.uid,
			created: firebase.firestore.FieldValue.serverTimestamp(),
		})
		/**
		 * Remove the current enquiry document
		 */
		await db.doc(`enquiries/${enquiryID}`).delete()
		/**
		 * Reset the state
		 */
		setConfirming(false)
		/**
		 * Redirect the user here to the itinerary page
		 */
		setRedirect(`/booking/${bookingID}`)
	}

	/**
	 * If there is a redirect present in the state
	 */
	if (redirect) {
		/**
		 * Redirect the page to that path
		 */
		return <Redirect to={redirect} />
	} else {
		/**
		 * If there is no redirect
		 */
		return (
			<Window
				title={`${client.first_name}'s enquiry`}
				subtitle={`From ${dateFromString} for a group size of ${groupSize}`}
				close={() => props.close()}>
				<WindowCard
					title="Enquiry summary"
					primaryAction={{
						icon: editing ? <SaveIcon /> : <PenIcon />,
						label: editing ? "Save" : "Edit",
						onClick: editing ? () => saveEnquiryDetails() : () => setEditing(true),
						loading: saving,
						loadingText: "Saving...",
					}}>
					<div className="input-grid cols-4">
						<div className="span-2">
							<Select
								showLabel
								value={agent}
								placeholder="Assigned agent:"
								selected={agent}
								readOnly={!editing}
								onSelect={(option) => setAgent(option.option)}
								options={agents}
							/>
						</div>

						<div className="span-2">
							<Select
								showLabel
								value={status}
								placeholder="Lead type:"
								selected={status}
								readOnly={!editing}
								onSelect={(option) => setStatus(option.option)}
								options={{
									HOT: "Hot",
									FREEPHONE: "Freephone",
									BEING_PROCESSED: "Processing",
								}}
							/>
						</div>

						<div className="span-2">
							<Input
								type="text"
								label="Location:"
								value={location}
								onChange={setLocation}
								placeholder="Location:"
								readOnly={!editing}
								showLabel
							/>
						</div>

						<div className="span-2">
							<Datepicker
								placeholder="Follow up date:"
								value={followUpDate}
								onSelect={(date) => setFollowUpDate(date)}
								readOnly={!editing}
								showLabel
							/>
						</div>

						<Input
							type="text"
							label="Nights:"
							value={nights}
							onChange={setNights}
							placeholder="Nights:"
							readOnly={!editing}
							showLabel
						/>
						<Input
							type="text"
							label="Rounds:"
							value={rounds}
							onChange={setRounds}
							placeholder="Rounds:"
							readOnly={!editing}
							showLabel
						/>
						<Input
							type="text"
							label="Group size:"
							value={groupSize}
							onChange={setGroupSize}
							placeholder="Group size:"
							readOnly={!editing}
							showLabel
						/>
						<Input
							type="text"
							label="Quoted:"
							value={quoted}
							onChange={setQuoted}
							placeholder="Quoted:"
							readOnly={!editing}
							showLabel
						/>

						<div className="span-4">
							<Textarea
								type="text"
								label="Private notes:"
								value={notes}
								onChange={setNotes}
								placeholder="Private notes:"
								readOnly={!editing}
								showLabel={editing}
								rows={10}
							/>
						</div>
					</div>

					{parameters?.utm_source && (
						<div style={{ marginTop: "12px", padding: "10px 14px", background: "#ECFEFF", border: "1px solid #A5F3FC", borderRadius: "4px" }}>
							<p style={{ fontSize: "14px", opacity: 0.65, marginBottom: "5px" }}>Tracking information:</p>
							<ul>
								{Object.keys(parameters).map((key) => {
									return (
										<li style={{ fontSize: "15px", marginBottom: "4px" }}>
											{key}: <strong style={{ fontWeight: 500 }}>{parameters[key]}</strong>
										</li>
									)
								})}
							</ul>
						</div>
					)}

					<div className="ui-window-actions">
						<Button
							small={true}
							label="Confirm as booking"
							loadingText="Saving as booking..."
							loading={confirming}
							onClick={() => confirmEnquiryAsBooking()}
						/>

						<Button
							small={true}
							className="OUTLINE"
							label="Remove enqiury"
							loadingText="Removing..."
							loading={removing}
							onClick={() => checkToRemoveEnquiry()}
						/>
					</div>
				</WindowCard>
			</Window>
		)
	}
}

export default ViewEnquiry
