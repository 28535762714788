import React, { useState, useEffect } from "react"
import { CALENDAR_MONTHS } from "../../utils/exports/months"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./conversions.scss"

// UI imports
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Table from "../../components/structure/table/table"
import Button from "../../components/ui/button/button"

// Build up a list of years for the select field
let yearsObj = {}
let nextYear = Number(moment().startOf("year").add(2, "years").format("YYYY"))
for (let i = nextYear; i >= 2018; i--) {
	yearsObj = {
		[i]: i,
		...yearsObj,
	}
}

// Store the channel names
const channels = [
	{
		site: "GHD",
		name: "Golf Holidays Direct",
	},
	{ site: "MGH", name: "My Golf Holidays" },
	{ site: "MGB", name: "My Golf Breaks" },
	{ site: "GBS", name: "Golf Breaks Spain" },
	{ site: "GBP", name: "Golf Breaks Portugal" },
	{ site: "SYSTEM", name: "System" },
]

// Returns the HTML markup for the conversions page
export default function Conversions() {
	const [loading, setLoading] = useState(false)
	const [users, setUsers] = useState([])

	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")

	const [userRows, setUserRows] = useState([])
	const [channelRows, setChannelRows] = useState([])

	// On component load
	useEffect(() => {
		const fetchSalesAgents = async () => {
			const salesAgents = db.collection("users").orderBy("first_name").where("show_for.targets", "==", true)
			const snapshot = await salesAgents.get()
			const agents = snapshot.docs.map((doc) => {
				return {
					id: doc.id,
					...doc.data(),
				}
			})
			setUsers(agents)
		}
		fetchSalesAgents()
	}, [])

	// Fetch the conversion data from the database
	const fetchConversionsData = async () => {
		// Reset the state
		setLoading(true)

		// Get the milliseconds timestamps from the chosen filters
		let fetchStart = 0
		let fetchEnd = 0
		if (month && year) {
			fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
			fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		} else if (!month && year) {
			fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf()
			fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf()
		}
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

		try {
			// Fetch data for all users concurrently
			const userResults = await Promise.all(
				users.map(async (user) => {
					const { id: agentID } = user

					// Fetch active, removed, and booked enquiries concurrently
					const [activeEnquiries, removedEnquiries, bookedEnquiries] = await Promise.all([
						db
							.collection("enquiries") //
							.where("agent", "==", agentID)
							.where("created", ">=", startTimestamp)
							.where("created", "<=", endTimestamp)
							.get(),

						db
							.collection("enquiries_removed") //
							.where("agent", "==", agentID)
							.where("enquired", ">=", startTimestamp)
							.where("enquired", "<=", endTimestamp)
							.get(),

						db
							.collection("bookings") //
							.where("agent", "==", agentID)
							.where("enquiry.created", ">=", startTimestamp)
							.where("enquiry.created", "<=", endTimestamp)
							.get(),
					])

					// Calculate counts
					const activeCount = activeEnquiries.size
					const removedCount = removedEnquiries.size
					const bookedCount = bookedEnquiries.size

					// Calculate total enquiries and conversion rate
					const totalEnquiries = activeCount + removedCount
					const conversionRate = totalEnquiries > 0 ? ((bookedCount / totalEnquiries) * 100).toFixed(2) : 0

					return {
						agent: user.first_name ? `${user.first_name} ${user.last_name}` : agentID,
						enquiries: totalEnquiries,
						bookings: bookedCount,
						conversionRate,
					}
				})
			)
			const channelResults = await Promise.all(
				channels.map(async (channel) => {
					const { site } = channel

					// Fetch active, removed, and booked enquiries concurrently
					const [activeEnquiries, removedEnquiries, bookedEnquiries] = await Promise.all([
						db
							.collection("enquiries") //
							.where("site", "==", site)
							.where("created", ">=", startTimestamp)
							.where("created", "<=", endTimestamp)
							.get(),

						db
							.collection("enquiries_removed") //
							.where("enquiry.site", "==", site)
							.where("enquired", ">=", startTimestamp)
							.where("enquired", "<=", endTimestamp)
							.get(),

						db
							.collection("bookings") //
							.where("enquiry.site", "==", site)
							.where("enquiry.created", ">=", startTimestamp)
							.where("enquiry.created", "<=", endTimestamp)
							.get(),
					])

					// Calculate counts
					const activeCount = activeEnquiries.size
					const removedCount = removedEnquiries.size
					const bookedCount = bookedEnquiries.size

					// Calculate total enquiries and conversion rate
					const totalEnquiries = activeCount + removedCount
					const conversionRate = totalEnquiries > 0 ? ((bookedCount / totalEnquiries) * 100).toFixed(2) : 0

					return {
						site: channel.site,
						name: channel.name,
						enquiries: totalEnquiries,
						bookings: bookedCount,
						conversionRate,
					}
				})
			)

			// Update state with results
			setUserRows(userResults)
			setChannelRows(channelResults)
		} catch (err) {
			console.error("Error fetching conversion data:", err)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Conversions</h1>
			</Title>

			<div className="page-filters extra-stats">
				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={yearsObj}
				/>

				<div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
					<Button
						filters={true}
						disabled={(!month || !year) && !year}
						label="Fetch"
						loadingText="Fetching..."
						loading={loading}
						onClick={() => fetchConversionsData()}
					/>
				</div>
			</div>

			<Table
				className="conversions-table"
				headings={["Name", "Enquiries", "Bookings", "Conversion rate"]}
				noResults={!channelRows.length}
				noResultsMessage="Choose some parameters to fetch conversion data">
				{channelRows.map((channelRow) => (
					<tr key={channelRow.site}>
						<td>{channelRow.name}</td>
						<td>{channelRow.enquiries}</td>
						<td>{channelRow.bookings}</td>
						<td>{channelRow.conversionRate}%</td>
					</tr>
				))}
			</Table>

			<Table
				className="conversions-table has-extra-margin"
				headings={["Agent", "Enquiries", "Bookings", "Conversion rate"]}
				noResults={!userRows.length}
				noResultsMessage="Choose some parameters to fetch conversion data">
				{userRows.map((userRow) => (
					<tr key={userRow.agentID}>
						<td>{userRow.agent}</td>
						<td>{userRow.enquiries}</td>
						<td>{userRow.bookings}</td>
						<td>{userRow.conversionRate}%</td>
					</tr>
				))}
			</Table>
		</Tile>
	)
}
