import { useState, useEffect } from "react"
import { db } from "../../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"

// Returns the HTML markup for a deleted enquiry row
export default function DeletedEnquiry({ enquiry }) {
	// Pull the relevant details from the enquiry
	const { site, agent, created, enquiry: baseEnquiry } = enquiry

	// States
	const [clientName, setClientName] = useState("")
	const [agentName, setAgentName] = useState("")

	// On component load
	useEffect(() => {
		if (agent) {
			db.doc(`users/${agent}`)
				.get()
				.then((doc) => {
					console.log(doc.data())
					setAgentName(`${doc.data().first_name} ${doc.data().last_name}`)
				})
		}

		if (baseEnquiry.client) {
			db.doc(`clients/${baseEnquiry.client}`)
				.get()
				.then((clientDoc) => {
					setClientName(`${clientDoc.data().first_name} ${clientDoc.data().last_name}`)
				})
		}
	}, [])

	// Format the removed date
	const removedOn = moment(created?.seconds, "X").format("MMM D[,] YYYY")
	const dateFrom = moment(baseEnquiry.date_from?.seconds, "X").format("MMM D[,] YYYY")
	const followUp = baseEnquiry.follow_up ? moment(baseEnquiry.follow_up?.seconds, "X").format("MMM D[,] YYYY") : "No follow up"

	return (
		<tr>
			<td>{site}</td>
			<td>
				<NavLink to={`client/${baseEnquiry.client}`}>{clientName}</NavLink>
			</td>
			<td>{baseEnquiry?.location}</td>
			<td>{dateFrom}</td>
			<td>
				{followUp === "No follow up" && <small>{followUp}</small>}

				{followUp !== "No follow up" && <>{followUp}</>}
			</td>
			<td>{baseEnquiry?.group_size}</td>
			<td>{agentName}</td>
			<td>{removedOn}</td>
		</tr>
	)
}
