import { NavLink } from "react-router-dom"
import moment from "moment"

// UI imports
import Badge from "../ui/badge/badge"
import Button from "../ui/button/button"

// Functional component to return the enquiry table row
export default function Cancellation({ id, details, maskDetails, showCancellationWindow }) {
	const { booking_data } = details

	// Format the dates
	const cancelledAt = moment(details.cancelled_at?.seconds, "X").format("MMM D[,] YYYY")
	const dateFrom = moment(booking_data.date_from?.seconds, "X").format("MMM D[,] YYYY")

	return (
		<tr data-system-id={id}>
			<td>
				{!maskDetails && <NavLink to={`client/${details.clientData.id}`}>{details.client_name || <small className="no-value">No name set</small>}</NavLink>}

				{maskDetails && <>{details.client_name || <small className="no-value">No name set</small>}</>}
			</td>
			<td>{details.agent_name || <small className="no-value">No agent assigned</small>}</td>
			<td>£{booking_data?.margins?.profit ? <>{booking_data?.margins?.profit.toFixed(2)}</> : 0}</td>
			<td>£{booking_data?.paid_by_client || 0}</td>
			<td>£{booking_data?.margins?.supplier_invoices || 0}</td>
			<td>{cancelledAt}</td>
			<td>{dateFrom}</td>
			<td>
				{/* Cancellation has been confirmed */}
				{details.resort_confirmed && (
					<Badge
						type="POSITIVE"
						label="Received & Accepted"
					/>
				)}

				{/* Cancellation has not yet been confirmed */}
				{!details.resort_confirmed && (
					<Badge
						type="NEGATIVE"
						label="Unconfirmed"
					/>
				)}
			</td>
			<td className="is-button">
				<Button
					small={true}
					label="View cancellation"
					onClick={() => showCancellationWindow(id)}
				/>
			</td>
		</tr>
	)
}
