import React, { useContext, useState } from "react"
import { BookingContext } from "../../../utils/providers/booking"
import moment from "moment"

/**
 * Email functions
 */
import { sendClientHolidayVouchers, sendClientItinerary, sendClientItineraryInsurance, sendPaymentReminderEmail, sendReviewRequestEmail } from "../../../utils/emails"

/**
 * UI components
 */
import Button from "../../../components/ui/button/button"
import Badge from "../../../components/ui/badge/badge"

/**
 * Functional component to return emails panel for the booking, used to send
 * itineraries and vouchers etc
 */
export default function Emails() {
	const [itinerarySending, setItinerarySending] = useState(false)
	const [itinerarySendingWOInsurance, setItinerarySendingWOInsurance] = useState(false)
	const [vouchersSending, setVouchersSending] = useState(false)
	const [reminderSending, setReminderSending] = useState(false)
	const [reviewSending, setReviewSending] = useState(false)

	/**
	 * Deconstruct data from the booking context
	 */
	const { bookingID, courses, hotels, transfers, itineraryStatus, vouchersStatus, paymentReminderStatus, reviewRequestStatus } = useContext(BookingContext)

	/**
	 * Build a date string from the itinerary status
	 */
	const itineraryDate = moment(itineraryStatus?.seconds, "X").format("DD/MM/YYYY [at] HH:mm")
	const vouchersDate = moment(vouchersStatus?.seconds, "X").format("DD/MM/YYYY [at] HH:mm")
	const paymentReminderDate = moment(paymentReminderStatus?.seconds, "X").format("DD/MM/YYYY [at] HH:mm")
	const reviewRequestDate = moment(reviewRequestStatus?.seconds, "X").format("DD/MM/YYYY [at] HH:mm")

	/**
	 * Send the itinerary email to the client
	 */
	const sendItineraryEmail = async () => {
		/**
		 * Show a loading spinner
		 */
		setItinerarySending(true)
		/**
		 * Send the itinerary
		 */
		await sendClientItinerary(bookingID, courses, hotels, transfers)
		await sendClientItineraryInsurance(bookingID)
		/**
		 * Reset the state
		 */
		setItinerarySending(false)
	}

	/**
	 * Send the itinerary email to the client
	 */
	const sendItineraryEmailWOInsurance = async () => {
		/**
		 * Show a loading spinner
		 */
		setItinerarySendingWOInsurance(true)
		/**
		 * Send the itinerary
		 */
		await sendClientItinerary(bookingID, courses, hotels, transfers)
		/**
		 * Reset the state
		 */
		setItinerarySendingWOInsurance(false)
	}

	/**
	 * Send the booking vouchers to the client
	 */
	const sendVoucherEmails = async () => {
		/**
		 * Show a loading spinner
		 */
		setVouchersSending(true)
		/**
		 * Send the vouchers
		 */
		await sendClientHolidayVouchers(bookingID, courses)
		/**
		 * Reset the state
		 */
		setVouchersSending(false)
	}

	/**
	 * Send a payment reminder to the client
	 */
	const sendPaymentReminder = async () => {
		/**
		 * Show a loading spinner
		 */
		setReminderSending(true)
		/**
		 * Send the payment reminder
		 */
		await sendPaymentReminderEmail(bookingID, courses)
		/**
		 * Reset the state
		 */
		setReminderSending(false)
	}

	/**
	 * Send a review request to the client
	 */
	const sendReviewRequest = async () => {
		/**
		 * Show a loading spinner
		 */
		setReviewSending(true)
		/**
		 * Send the payment reminder
		 */
		await sendReviewRequestEmail(bookingID)
		/**
		 * Reset the state
		 */
		setReviewSending(false)
	}

	return (
		<>
			<table className="booking-table">
				<tbody>
					<tr>
						<td>Itinerary email</td>
						<td className="vertical-center">
							<Button
								label="Send itinerary"
								xsSmall={true}
								loading={itinerarySending}
								loadingText="Sending email..."
								onClick={() => sendItineraryEmail()}
							/>

							<div style={{ marginBottom: "10px" }} />

							<Button
								label="Send itinerary w/o Insurance"
								xsSmall={true}
								loading={itinerarySendingWOInsurance}
								loadingText="Sending email..."
								onClick={() => sendItineraryEmailWOInsurance()}
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td className="vertical-center">
							{/* Itinerary has not yet been sent */}
							{!itineraryStatus && <Badge label="Itinerary not sent yet" />}

							{/* Itinerary has been sent to the client */}
							{itineraryStatus && (
								<Badge
									type="INFO"
									label={`Itinerary sent ${itineraryDate}`}
								/>
							)}
						</td>
					</tr>
					<tr>
						<td colSpan="2">
							<div className="table-room-lower-border" />
						</td>
					</tr>
					<tr>
						<td>Voucher emails</td>
						<td className="vertical-center">
							<Button
								label="Send vouchers"
								xsSmall={true}
								loading={vouchersSending}
								loadingText="Sending emails..."
								onClick={() => sendVoucherEmails()}
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td className="vertical-center">
							{/* Vouchers have not yet been sent */}
							{!vouchersStatus && <Badge label="Vouchers not sent yet" />}

							{/* Vouchers have been sent to the client */}
							{vouchersStatus && (
								<Badge
									type="INFO"
									label={`Vouchers sent ${vouchersDate}`}
								/>
							)}
						</td>
					</tr>
					<tr>
						<td colSpan="2">
							<div className="table-room-lower-border" />
						</td>
					</tr>
					<tr>
						<td>Payment reminder</td>
						<td className="vertical-center">
							<Button
								label="Send reminder"
								xsSmall={true}
								loading={reminderSending}
								loadingText="Sending reminder..."
								onClick={() => sendPaymentReminder()}
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td className="vertical-center">
							{/* Payment reminder not yet been sent */}
							{!paymentReminderStatus && <Badge label="Payment reminder not sent yet" />}

							{/* Payment reminder been sent to the client */}
							{paymentReminderStatus && (
								<Badge
									type="INFO"
									label={`Reminder sent ${paymentReminderDate}`}
								/>
							)}
						</td>
					</tr>
					<tr>
						<td colSpan="2">
							<div className="table-room-lower-border" />
						</td>
					</tr>
					<tr>
						<td>Review request</td>
						<td className="vertical-center">
							<Button
								label="Send review request"
								xsSmall={true}
								loading={reviewSending}
								loadingText="Sending review request..."
								onClick={() => sendReviewRequest()}
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td className="vertical-center">
							{/* Review not yet been sent */}
							{!reviewRequestStatus && <Badge label="Review request not sent yet" />}

							{/* Review been sent to the client */}
							{reviewRequestStatus && (
								<Badge
									type="INFO"
									label={`Request sent ${reviewRequestDate}`}
								/>
							)}
						</td>
					</tr>
				</tbody>
			</table>
		</>
	)
}
