import * as XLSX from "xlsx"

const exportToExcel = (data, fileName) => {
	// Convert the data to a worksheet
	const worksheet = XLSX.utils.json_to_sheet(data)

	// Create a new workbook
	const workbook = XLSX.utils.book_new()

	// Append the worksheet to the workbook
	XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

	// Generate a binary string and trigger a download
	XLSX.writeFile(workbook, `${fileName}.xlsx`)
}

export default exportToExcel
