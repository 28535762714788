import { useState, useEffect, useContext, useRef } from "react"
import { ModalContext } from "../../../../../utils/providers/modal"
import { AlertsContext } from "../../../../../utils/providers/alerts"
import firebase from "firebase"
import moment from "moment"

// Utility functions
import { db } from "../../../../../utils/firebase"

// UI components
import MultipleSelect from "../../../../../components/ui/select/multiple-select"
import Datepicker from "../../../../../components/ui/datepicker/datepicker"
import Select from "../../../../../components/ui/select/select"
import Button from "../../../../../components/ui/button/button"
import Input from "../../../../../components/ui/inputs/input"

// Returns the HTML markup for an invoice in the finances tab
export default function Invoice({ index, bookingID, details, bookingItems, connectedInvoices, refetch }) {
	const [removing, setRemoving] = useState(false)

	// State variables for the invoice
	const [amount, setAmount] = useState(details.amount || null)
	const [internalReference, setInternalReference] = useState(details.internal_reference || null)
	const [externalReference, setExternalReference] = useState(details.external_reference || null)
	const [currency, setCurrency] = useState(details.currency || null)
	const [datePaid, setDatePaid] = useState(details.date_paid?.seconds ? moment(details.date_paid?.seconds, "X").valueOf() : null)
	const [bookingItemIDs, setBookingItemIDs] = useState(details.itemIDs || [])
	const [paymentStatus, setPaymentStatus] = useState(details.payment_status || null)

	// Get the modal and alert functions from the contexts
	const { showModal } = useContext(ModalContext)
	const { pushAlert } = useContext(AlertsContext)

	// Create a ref to check if the component is loading for the first time
	const initialLoad = useRef(false)

	// On component load
	useEffect(() => {
		// If the component is loading for the first time, set the initial load to true
		if (!initialLoad.current) {
			setTimeout(() => {
				initialLoad.current = true
			}, 2500)
		}
	}, [])

	// When any of the invoice details change, update the invoice document in the booking subcollection
	useEffect(() => {
		saveInvoiceDetails()
	}, [currency, datePaid, bookingItemIDs, paymentStatus])

	// Create an object of all the booking items, using the finance_id as the key and email as the value
	let dropdownItems = {}
	bookingItems.forEach((bookingItem) => {
		dropdownItems[bookingItem.finance_id] = `[${bookingItem.finance_type?.toUpperCase()}] ${bookingItem.finance_email}`
	})

	// Remove any items from the dropdownItems where the key is already connected to an invoice
	connectedInvoices.forEach((connectedInvoice) => {
		delete dropdownItems[connectedInvoice.finance_id]
	})

	// Save the invoice details into the booking subcollection
	const saveInvoiceDetails = async () => {
		if (!initialLoad.current) return

		// If all the values we have match what were given by the details object
		if (
			amount === details.amount &&
			internalReference === details.internal_reference &&
			externalReference === details.external_reference &&
			currency === details.currency &&
			datePaid === details.date_paid &&
			bookingItemIDs === details.itemIDs &&
			paymentStatus === details.payment_status
		) {
			return
		}

		// Update the invoice document in the booking subcollection
		await db.doc(`bookings/${bookingID}/supplier_invoices/${details.id}`).set(
			{
				amount: Number.parseFloat(amount),
				internal_reference: internalReference,
				external_reference: externalReference,
				currency: currency,
				date_paid: datePaid ? firebase.firestore.Timestamp.fromDate(moment(datePaid).toDate()) : null,
				itemIDs: bookingItemIDs,
				payment_status: paymentStatus,
				updated: firebase.firestore.FieldValue.serverTimestamp(),
			},
			{ merge: true }
		)

		// Show an alert to the user
		pushAlert({
			type: "SUCCESS",
			title: "Invoice Updated",
			body: "The invoice details have been updated.",
		})

		// Refetch the booking items
		refetch()
	}

	// Double check the invoice should be removed before doing so
	const checkBeforeRemoval = () => {
		showModal({
			type: "ALERT",
			title: "Are you sure?",
			body: "Are you sure you'd like to remove this invoice from the booking?",
			cancel: {
				label: "Cancel",
				action: () => {
					return null
				},
			},
			next: {
				label: "Yes, i'm sure",
				action: () => removeInvoice(),
			},
		})
	}

	// Remove the invoice document from the booking subcollection
	const removeInvoice = async () => {
		// Set the removing state
		setRemoving(true)

		// Remove the invoice document
		await db.doc(`bookings/${bookingID}/supplier_invoices/${details.id}`).delete()

		// Show an alert to the user
		pushAlert({
			type: "SUCCESS",
			title: "Invoice Removed",
			body: "The invoice has been removed from the booking document.",
		})

		// Reset the removing state
		setRemoving(false)

		// Then refetch the booking items
		refetch()
	}

	return (
		<table className="booking-table">
			<tbody>
				<tr>
					<td colSpan="2">
						<div className="table-room-lower-border" />
					</td>
				</tr>
				<tr className="table-sub-heading">
					<td>Invoice #{index + 1}</td>
					<td>
						<Button
							badge={true}
							label="Remove"
							className="OUTLINE"
							loading={removing}
							loadingText="Removing..."
							onClick={() => checkBeforeRemoval()}
						/>
					</td>
				</tr>

				<tr>
					<td>Connected item(s)</td>
					<td>
						<MultipleSelect
							placeholder="Choose booking item(s)"
							selected={details.itemIDs}
							options={dropdownItems}
							onSelect={(selectedOptions) => {
								// Get an array of all the selected options
								let selectedKeys = []

								// Loop through the selected options and add the keys to the array
								selectedOptions.forEach((option) => {
									selectedKeys.push(option.option)
								})

								// Set the selected keys into the state
								setBookingItemIDs(selectedKeys)
							}}
						/>
					</td>
				</tr>

				<tr>
					<td>Internal reference</td>
					<td>
						<Input
							type="text"
							value={internalReference}
							onChange={setInternalReference}
							placeholder="Enter reference:"
							onBlur={() => saveInvoiceDetails()}
						/>
					</td>
				</tr>

				<tr>
					<td>Invoice amount</td>
					<td>
						<Input
							type="text"
							value={amount}
							onChange={setAmount}
							placeholder="Enter amount:"
							onBlur={() => saveInvoiceDetails()}
						/>
					</td>
				</tr>

				<tr>
					<td>Invoice currency</td>
					<td>
						<Select
							placeholder="Set payment currency"
							selected={currency}
							options={{
								GBP: "GBP",
								EUR: "EUR",
								USD: "USD",
							}}
							onSelect={(selected) => setCurrency(selected.option)}
						/>
					</td>
				</tr>

				<tr>
					<td>External reference</td>
					<td>
						<Input
							type="text"
							value={externalReference}
							onChange={setExternalReference}
							placeholder="Enter reference:"
							onBlur={() => saveInvoiceDetails()}
						/>
					</td>
				</tr>

				<tr>
					<td>Payment status</td>
					<td>
						<Select
							placeholder="Set payment status"
							selected={paymentStatus}
							options={{
								PAID: "Paid",
								UNPAID: "Unpaid",
								PARTIAL: "Partial",
								CREDIT: "Credit",
								REQUESTED: "Requested",
							}}
							onSelect={(selected) => setPaymentStatus(selected.option)}
						/>
					</td>
				</tr>

				<tr>
					<td>Paid date</td>
					<td>
						<Datepicker
							placeholder="Choose date:"
							value={datePaid}
							onSelect={(date) => setDatePaid(date)}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	)
}
