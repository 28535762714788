import firebase from "firebase"
import moment from "moment"

// UI components
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"

// Charts
import EnquiriesLineChart from "./charts/enquiries"
import BookingsLineChart from "./charts/bookings"
import MarginsLineChart from "./charts/margins"
import GolfersLineChart from "./charts/golfers"
import SalesLineChart from "./charts/sales"

// Returns YoY charting information, per month
export default function Charting() {
	const thisMonthStart = firebase.firestore.Timestamp.fromMillis(moment().startOf("month").valueOf())
	const thisMonthEnd = firebase.firestore.Timestamp.fromMillis(moment().endOf("month").valueOf())
	const lastYearStart = firebase.firestore.Timestamp.fromMillis(moment().subtract(1, "year").startOf("month").valueOf())
	const lastYearEnd = firebase.firestore.Timestamp.fromMillis(moment().subtract(1, "year").endOf("month").valueOf())

	return (
		<Tile>
			<Title>
				<h1>Statistic Charting</h1>
			</Title>

			<MarginsLineChart
				tmStart={thisMonthStart}
				tmEnd={thisMonthEnd}
				lyStart={lastYearStart}
				lyEnd={lastYearEnd}
			/>

			<br />

			<SalesLineChart
				tmStart={thisMonthStart}
				tmEnd={thisMonthEnd}
				lyStart={lastYearStart}
				lyEnd={lastYearEnd}
			/>

			<br />

			<EnquiriesLineChart
				tmStart={thisMonthStart}
				tmEnd={thisMonthEnd}
				lyStart={lastYearStart}
				lyEnd={lastYearEnd}
			/>

			<br />

			<BookingsLineChart
				tmStart={thisMonthStart}
				tmEnd={thisMonthEnd}
				lyStart={lastYearStart}
				lyEnd={lastYearEnd}
			/>

			<br />

			<GolfersLineChart
				tmStart={thisMonthStart}
				tmEnd={thisMonthEnd}
				lyStart={lastYearStart}
				lyEnd={lastYearEnd}
			/>
		</Tile>
	)
}
